<template>
  <div style="display: table; margin: 0 auto">
    <div
      v-for="installProgress in installStatusNames"
      :key="installProgress.type"
      class="row"
      style="padding: 5px; display: table-row"
    >
      <Progress-spinner
        v-if="installProgress.status === null"
        style="
          width: 30px;
          height: 30px;
          vertical-align: middle;
          display: table-cell;
          padding-right: 5px;
        "
        strokeWidth="2"
        fill="#EEEEEE"
        animationDuration="1s"
      />
      <i
        v-else-if="installProgress.status"
        class="pi pi-check"
        style="
          font-size: 30px;
          color: green;
          vertical-align: middle;
          display: table-cell;
          padding-right: 5px;
        "
      />
      <i
        v-else
        class="pi pi-times"
        style="
          font-size: 30px;
          color: red;
          vertical-align: middle;
          display: table-cell;
          padding-right: 5px;
        "
      />
      <span
        style="
          margin-left: 10px;
          display: table-cell;
          vertical-align: middle;
          padding-left: 5px;
        "
      >
        {{ installProgress.name }}
      </span>
    </div>
  </div>
</template>

<script>
import ProgressSpinner from "primevue/progressspinner";

export default {
  name: "stepThree",
  components: {
    ProgressSpinner,
  },
  props: {
    language: {
      type: String,
      required: true,
    },
    downloading: {
      type: [Boolean, String],
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    serialKey: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
  emits: ["update:step", "update:message"],
  data() {
    return {
      installStatus: [
        {
          type: "download",
          status: null,
          nameCode: `downloadSystemProgress`,
          name: ``,
        },
        {
          type: "unzip",
          status: null,
          nameCode: `unzipSystemProgress`,
          name: "",
        },
        {
          type: "cleanup",
          status: null,
          nameCode: `cleanupProgress`,
          name: "",
        },
      ],
      checkInterval: null,
      archiveStatus: "wait",
    };
  },
  computed: {
    installStatusNames() {
      return this.installStatus.map((status) => {
        status.name = this.$t(`message.${status.nameCode}`, this.language);
        return status;
      });
    },
  },
  watch: {
    downloading(newValue) {
      if (newValue !== "ready") {
        this.checkInterval = setTimeout(() => {
          this.checkDownload();
        }, 5000);
      }
    },
    archiveStatus(_, oldValue) {
      switch (oldValue) {
        case "wait":
          this.installStatus[0].status = true;
          this.runUnzip();
          break;
        case "ready":
          this.installStatus[1].status = true;
          this.runCleanup();
          break;
        case "unzipped":
          window.location.reload();
          break;
      }
    },
  },
  methods: {
    runCleanup() {
      fetch(`${this.apiUrl}/api.php?command=cleanup`).then(() => {
        this.archiveStatus = "done";
      });
    },
    runUnzip() {
      fetch(`${this.apiUrl}/api.php?command=unzip`).then(() => {
        this.archiveStatus = "unzipped";
      });
    },
    checkDownload() {
      const data = {
        serialKey: this.serialKey,
      };

      fetch(`${this.apiUrl}/api.php?command=download`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((data) => {
          if (data.ok) {
            return data.json();
          }
        })
        .then((json) => {
          if (json.download === "ready") {
            this.archiveStatus = "ready";
            clearTimeout(this.checkInterval);
          } else if (json.download === "error") {
            this.archiveStatus = "wait";
            clearTimeout(this.checkInterval);

            this.$emit("update:step", 2);
            this.$emit(
              "update:message",
              this.$t(`message.downloadError`, this.language)
            );
          } else {
            this.checkInterval = setTimeout(() => {
              this.checkDownload();
            }, 5000);
          }
        });
    },
  },
};
</script>

<style scoped></style>
