<template>
  <div class="col-xs-5ths text-center padding-10" :class="this.class">
    <h5>{{ this.name }}</h5>
  </div>
</template>

<script>
export default {
  name: "installStep",
  props: {
    name: {
      type: String,
      required: true,
    },
    class: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped></style>
