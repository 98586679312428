<template>
  <h5>{{ $t("message.setLanguage", language) }}</h5>
  <Dropdown
    @change="$emit('update:language', $event.value)"
    :model-value="language"
    :options="languages"
    option-label="name"
    option-value="code"
    style="width: 100%"
  ></Dropdown>
</template>

<script>
import Dropdown from "primevue/dropdown";

export default {
  name: "languageSelector",
  components: {
    Dropdown,
  },
  props: {
    language: {
      type: String,
    },
  },
  emits: ["update:language"],
  data() {
    return {
      languages: [
        {
          code: "russian",
          name: "Русский",
        },
        {
          code: "english",
          name: "English",
        },
        {
          code: "ukrainian",
          name: "Українська",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
