import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import PrimeVue from "primevue/config";
import App from "./App.vue";

import "@/assets/css/main.css";

const messages = {
  russian: {
    message: {
      requirementsStep: "Требования",
      checkPermissionInstallStep: "Проверка",
      downloadStep: "Загрузка",
      setLanguage: "Выберите язык",
      checkFail:
        "Данный домен не имеет прав на установку и скачивание Perfectum CRM+ERP",
      extension: "Расширение",
      result: "Результат",
      extensionName: 'PHP расширение "{0}"',
      extensionEnabled: "Включено",
      extensionDisabled: "Выключено",
      settingName: 'PHP настройка "{0}"',
      settingMemoryLimitRecommend: "Рекомендуется 256MБ +",
      seconds: "секунд",
      settingMaxExecutionTimeRecommend: "Рекомендуется 300+ секунд",
      nextStep: "Следующий шаг",
      serialKey: "Серийный ключ",
      downloadError:
        "Произошла ошибка при скачивании системы. Попробуйте позже",
      downloadSystemProgress: "Загрузка архива",
      unzipSystemProgress: "Распаковка архива",
      cleanupProgress: "Удаление временных файлов",
    },
  },
  english: {
    message: {
      requirementsStep: "Requirements",
      checkPermissionInstallStep: "Checking",
      downloadStep: "Download",
      setLanguage: "Select language",
      checkFail:
        "This domain doesn't have a permission to download and install Perfectum CRM+ERP",
      extension: "Extension",
      result: "Result",
      extensionName: 'PHP Extension "{0}"',
      extensionEnabled: "Enabled",
      extensionDisabled: "Disabled",
      settingName: 'PHP setting "{0}"',
      settingMemoryLimitRecommend: "256MB + recommended",
      seconds: "seconds",
      settingMaxExecutionTimeRecommend: "300+ seconds recommended",
      nextStep: "Next step",
      serialKey: "Serial key",
      downloadError:
        "An error occurred while downloading system. Please try later",
      downloadSystemProgress: "Downloading archive",
      unzipSystemProgress: "Unzip archive",
      cleanupProgress: "Deleting temp files",
    },
  },
  ukrainian: {
    message: {
      requirementsStep: "Вимоги",
      checkPermissionInstallStep: "Перевірка",
      downloadStep: "Завантаження",
      setLanguage: "Виберіть мову",
      checkFail:
        "Данний домен не має права на завантаження та встановлення Perfectum CRM+ERP",
      extension: "Розширення",
      result: "Результат",
      extensionName: 'PHP розширення "{0}"',
      extensionEnabled: "Включено",
      extensionDisabled: "Не ввімкнено",
      settingName: 'PHP налаштування "{0}"',
      settingMemoryLimitRecommend: "Рекомендовано 256МБ +",
      seconds: "секунд",
      settingMaxExecutionTimeRecommend: "Рекомендовано 300+ секунд",
      nextStep: "Наступний крок",
      serialKey: "Серійний ключ",
      downloadError:
        "Під час завантаження системи сталася помилка. Будь ласка, спробуйте пізніше",
      downloadSystemProgress: "Завантаження архіву",
      unzipSystemProgress: "Розпаковка архіву",
      cleanupProgress: "Видалення тимчасових файлів",
    },
  },
};

const i18n = createI18n({
  locale: "russian",
  fallbackLocale: "english",
  messages,
});

createApp(App).use(i18n).use(PrimeVue).mount("#app");
