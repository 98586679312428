<template>
  <Data-table
    :value="requirementsStepFirstCalculated"
    responsive-layout="scroll"
  >
    <Column
      field="extension"
      :header="$t('message.extension', language)"
    ></Column>
    <Column :header="$t('message.result', language)">
      <template #body="{ data }">
        <span :class="'label label-' + data.color">{{ data.result }}</span>
      </template>
    </Column>
  </Data-table>
  <div v-if="allowNextStep" class="text-center" style="margin-top: 20px">
    <Button
      :label="$t(`message.nextStep`, language)"
      class="p-button-raised p-button-success"
      @click="$emit('update:step', 2)"
    />
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";

export default {
  name: "stepOne",
  components: {
    DataTable,
    Column,
    Button,
  },
  data() {
    return {
      requirements: [],
      allowNextStep: true,
    };
  },
  props: {
    language: {
      type: String,
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    step: {
      type: Number,
    },
  },
  emits: ["update:step"],
  computed: {
    requirementsStepFirstCalculated() {
      let requirements = this.requirements;
      let requirementsReturn = {};

      requirementsReturn = requirements.map((element) => {
        let extensionName = element.type;
        let result = element.result;
        let color = "warning";

        switch (element.type) {
          case "php":
            extensionName = "PHP 7.2 / PHP 7.3";
            if (element.result >= "7.4" || element.result < "7.2") {
              color = "danger";
            } else {
              color = "success";
            }
            break;
          case "cURL":
          case "OpenSSL":
          case "Zip":
          case "json":
          case "ionCube Loader":
            extensionName = this.$t(`message.extensionName`, this.language, [
              element.type,
            ]);
            if (element.result === true) {
              result = this.$t("message.extensionEnabled", this.language);
              color = "success";
            } else {
              result = this.$t("message.extensionDisabled", this.language);
              color = "danger";
            }
            break;
          case "memory_limit":
            extensionName = this.$t(`message.settingName`, this.language, [
              element.type,
            ]);
            if (element.result < 256 * 1024 * 1024) {
              result = `${element.result / (1024 * 1024)} MB (${this.$t(
                "message.settingMemoryLimitRecommend",
                this.language
              )})`;
              color = "warning";
            } else {
              result = `${element.result / (1024 * 1024)} MB`;
              color = "success";
            }
            break;
          case "max_execution_time":
            extensionName = this.$t(`message.settingName`, this.language, [
              element.type,
            ]);
            if (element.result === "0" || element.result === "-1") {
              result = `Ꝏ ${this.$t("message.seconds", this.language)}`;
              color = "success";
            } else if (element.result < 300) {
              result = `${element.result} ${this.$t(
                "message.seconds",
                this.language
              )} (${this.$t(
                "message.settingMaxExecutionTimeRecommend",
                this.language
              )})`;
              color = "warning";
            } else {
              result = `${element.result} ${this.$t(
                "message.seconds",
                this.language
              )}`;
              color = "success";
            }
            break;
        }

        return {
          extension: extensionName,
          color,
          result,
        };
      });

      this.requirementsStepFirstCheck();
      return requirementsReturn;
    },
  },
  methods: {
    requirementsStepFirstCheck() {
      this.allowNextStep = true;

      let stepAllow = this.allowNextStep;
      let requirements = this.requirements;

      requirements.map((element) => {
        switch (element.type) {
          case "php":
            if (element.result >= "7.4" || element.result < "7.2") {
              stepAllow = false;
            }
            break;
          case "cURL":
          case "OpenSSL":
          case "Zip":
          case "json":
          case "ionCube Loader":
            if (element.result !== true) {
              stepAllow = false;
            }
            break;
        }
      });

      this.allowNextStep = stepAllow;
    },
  },
  beforeMount() {
    fetch(`${this.apiUrl}/api.php?command=stepOne`)
      .then((data) => data.json())
      .then((json) => {
        this.requirements = json.requirements;
      });
  },
};
</script>
