<template>
  <Message severity="error" :closable="true" v-if="message.length">
    {{ message }}
  </Message>
  <div class="p-field">
    <label for="serialKey">{{ $t("message.serialKey", language) }}</label>
    <InputText
      id="serialKey"
      type="text"
      style="width: 100%"
      v-model="serialKey"
    />
  </div>
  <div class="text-center" style="margin-top: 20px">
    <Button
      :label="$t(`message.nextStep`, language)"
      class="p-button-raised p-button-success"
      @click="downloadCrm"
      :disabled="!allowNextStep"
    />
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Message from "primevue/message";

export default {
  name: "stepTwo",
  components: {
    InputText,
    Button,
    Message,
  },
  props: {
    language: {
      type: String,
      required: true,
    },
    message: {
      type: String,
    },
  },
  emits: ["downloadCrm"],
  data() {
    return {
      serialKey: "",
    };
  },
  computed: {
    allowNextStep() {
      return this.serialKey.length > 0;
    },
  },
  methods: {
    downloadCrm() {
      this.$emit("downloadCrm", this.serialKey);
    },
  },
};
</script>

<style scoped>
.sizes .p-inputtext {
  display: block;
  margin-bottom: 0.5rem;
}

.sizes:last-child {
  margin-bottom: 0;
}

.p-field * {
  display: block;
}
</style>
